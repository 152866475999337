<div class="bgwrapper b-lightgray"></div>
<div class="modalwrapper fadein b-white modalborder">
    <div class="modalheadcontainer p-20">
        <h1>Neuen Eintrag erstellen</h1>
    </div>
    <div class="modalcontentcontainer b-lightgrey p-20">

        <form #f="ngForm" name="f">
        <div class="row">
            <div class="col-md-12">
                <h2>Titel</h2>
                <input type="text" name="title" autocomplete="off" ngModel 
                #title="ngModel" 
                required 
                pattern="[A-Za-z0-9äüöÄÜÖß ]{1,255}" 
                [className]="title.errors?.pattern ? 'errorform' : ''" 
                />
                <p *ngIf="title.errors?.pattern">Nur Buchstaben und Zahlen und max 100 Zeichen.</p>
                <p *ngIf="!title.errors?.pattern">---</p>
            </div>
        </div>
        <div class="row m-b-20">
            <div class="col-md-12">
                <h2>Beschreibung</h2>
                <textarea class="textareaheight" name="description" rows="4" ngModel required 
                #description="ngModel" 
                pattern="[a-zA-Z0-9öäüÖÄÜß!?%&amp;()=:;#*+-&euro;&quot;\[\n\r\] ]{1,300}"
                [className]="description.errors?.pattern ? 'textareaheight errorform' : 'textareaheight'" 
                ></textarea>
                <p *ngIf="description.errors?.pattern">Keine unerlaubten Zeichen und max 300 Zeichen.</p>
                <p *ngIf="!description.errors?.pattern">---</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h2>Produktbild</h2>
                <div class="imagecontainer">
                    <input #imagefileinput class="fileinput" type="file" accept="image/*" capture="" (change)="processImage($event)" />
                    <img #itemimage src="" class="img mb-20" />
                    <div class="actionscontainer d-flex justify-content-end">
                        <actionbutton src="../../../assets/bs-add.svg" title="Bild hinzufügen" class="filebutton mr-10" (click)="imagefileinput.click()"></actionbutton>
                        <actionbutton src="../../../assets/bs-trash.svg" title="Bild entfernen" class="filebutton" (click)="deleteImage()"></actionbutton>
                    </div>
                </div>
            </div>
        </div>
        </form>

    </div>

    <div class="modalbuttoncontainer p-20 d-flex justify-content-end">
        <button class="button_light mr-20" (click)="emitOnClose()">Abbrechen</button><button class="button" (click)="emitOnCreate(f.value)">Speichern</button>
    <div>

</div>
