<div class="container-fluid elevation-3dp mb-20">
    <div class="row">
        <div class="col-12 p-20 b-primary-light">
            <ng-content select="[slot=headline]"></ng-content>
        </div>
    </div>
    <div class="row mb-10">
        <div class="col-12 p-20 b-primary-medium">
            <ng-content select="[slot=title]"></ng-content>
        </div>
    </div>
</div>