<div class="datarow pt-10 pb-10 elevation-3dp">
    <div class="container-fluid">
        <div class="row">
                <div class="col-auto p-0 m-0"><ng-content select="[slot=icon]"></ng-content></div>
                <div class="col">
                    <div class="row">
                        <div class="col-12 mb-0"><ng-content select="[slot=title]"></ng-content></div>
                    </div>
                </div>
                <div class="col-12 col-lg-auto p-0 d-flex justify-content-end align-items-center"><ng-content select="[slot=actions]"></ng-content></div>
        </div>
    </div>
</div>


